@import "../settings";

$pull-up-amount: 420px;

.content-image-repeater {
  padding-top: $global-padding * 4;
  z-index: 2; // To overlap parallax scrolling cards

  @include breakpoint(medium) {
    padding-top: $global-padding * 6;
  }

  @include breakpoint(large) {
    padding-top: 0;
  }

  &__grid {
    @include breakpoint(medium) {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: $default-grid-gap;
    }
  }

  &__heading {
    margin-bottom: $global-margin;
    position: relative;

    &::before {
      @include breakpoint(medium) {
        content: "";
        display: block;
        width: 119px;
        height: 112px;
        background-image: url('../images/stars-green-on-white-left.gif');
        background-repeat: no-repeat;
        position: absolute;
        top: -80px;
        left: -30px;
      }
      @include breakpoint(large) {
        top: -110px;
      }
    }
  }

  &__subheading {
    @include breakpoint(medium) {
      margin-left: auto;
      margin-right: auto;
      max-width: 510px;
    }
  }

  &__item {
    padding-bottom: $global-padding;
    position: relative;
    text-align: center;

    @include breakpoint(medium) {
      // Center text vertically in first item
      &:first-child {
        padding-bottom: #{$pull-up-amount};
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      // Display every odd/even item in alternating columns
      &:nth-child(2n + 1) {
        grid-column: 1/7;
      }

      &:nth-child(2n + 2) {
        grid-column: 7/13;
      }

      // Pull up every 3rd item
      &:nth-child(2n + 3) {
        margin-top: -#{$pull-up-amount};
      }

      &:nth-child(2n + 5)::before {
        content: "";
        display: block;
        width: 119px;
        height: 112px;
        background-image: url('../images/stars-green-on-white-left.gif');
        background-repeat: no-repeat;
        position: absolute;
        top: -80px;
        left: -70px;
      }

      // Stars added to every 4th (right) item
      &:nth-child(2n + 4):before {
        content: "";
        display: block;
        width: 119px;
        height: 112px;
        background-image: url('../images/stars-green-on-white-left.gif');
        background-repeat: no-repeat;
        position: absolute;
        top: -80px;
        right: 0;

        @include breakpoint(large) {
          right: -40px;
          top: 30px;
        }

        @include breakpoint(xxlarge) {
          right: -90px;
          top: -80px;
        }

      }
    }

    .lead {
      color: $secondary-color;
    }

    @media screen and (prefers-reduced-motion: reduce) {
      transform: inherit !important;
    }
    @include breakpoint(small only) {
      transform: inherit !important;
    }
  }

  // Bottom image (container), if exists at the bottom
  &__item-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: $global-padding * 2;
  }

  // Bottom image <img>
  &__bottom-image {
    max-width: 75%;
    margin-top: $global-margin * 2;

    @include breakpoint(medium) {
      max-width: 90%;
    }
  }

  // Regular item card images
  &__image {
    width: 100%;
  }

  // Card image (with mask)
  &__card {
    transition: none;
    margin-bottom: $global-margin;

    &:hover {
      .card__image {
        transform: scale(1);
      }
    }
  }

  &.block--pull-up {
    @include breakpoint(large) {
      margin-top: -20px; // Parallax takes most of the pull up out
    }

    .content-image-repeater__item {
      // Center text vertically in first item
      &:first-child {
        @include breakpoint(medium) {
          padding-bottom: 500px;
          justify-content: flex-end;
        }

        @include breakpoint(large) {
          padding-bottom: 320px;
          justify-content: center;
        }
      }
    }
  }
}
